<template>
    <ion-header class="ion-no-border border-b border-gray-50">
        <ion-toolbar>
            <ion-buttons slot="start">
                <ion-button @click="closeModal()">
                    <ion-img :src="require('@/assets/Icons/arrow-back-grey.svg')" class="w-6 h-6"></ion-img>
                </ion-button>
            </ion-buttons>
            <ion-title><ion-img :src="require('@/assets/images/SustainAbility-logo.svg')" class="w-20 mx-auto"></ion-img></ion-title>
        </ion-toolbar>
    </ion-header>
    <ion-content>
        <div class="flex flex-col flex-wrap p-7.5 h-full" v-if="type === 'info'">
            <h1 class="flex justify-center items-center mb-6 text-gray-400"><ion-icon slot="icon-only" :icon="informationCircle" size="large" class="mr-2 w-6 h-6"></ion-icon> Product Information</h1>
            <h2 class="mb-2 font-bold">Why is this Sustainable?</h2>
            <div class="mb-10" v-html="content.short_description"></div>
            <h2 class="mb-2 font-bold">Why Swap?</h2>
            <div class="">
                <ul class="flex flex-wrap gap-2 text-xs sm:text-sm mb-10">
                    <li
                        class="px-2 py-1 text-gray-500 bg-green-100 rounded-full opacity-95"
                        v-for="usp in content.usps"
                        :key="usp"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42" class="inline w-4 h-4 text-smcc-smcc-green fill-current">
                            <g fill="none" fill-rule="evenodd">
                                <path d="M0 0h42v42H0z"/>
                                <path fill="currentColor" d="M21 1.5c10.77 0 19.5 8.73 19.5 19.5S31.77 40.5 21 40.5 1.5 31.77 1.5 21 10.23 1.5 21 1.5Zm0 3C11.887 4.5 4.5 11.887 4.5 21S11.887 37.5 21 37.5 37.5 30.113 37.5 21 30.113 4.5 21 4.5Zm7.606 19.27a.692.692 0 0 1 .677.88c-1.038 3.487-4.345 6.042-8.28 6.042-3.93 0-7.236-2.555-8.282-6.043a.692.692 0 0 1 .68-.88h15.205Zm-13.837-6.924a2.077 2.077 0 1 1 0 4.154 2.077 2.077 0 0 1 0-4.154Zm12.462 0a2.077 2.077 0 1 1 0 4.154 2.077 2.077 0 0 1 0-4.154Z"/>
                            </g>
                        </svg>
                        {{ usp.name }}
                    </li>
                </ul>
                <h2 class="mb-2 font-bold">Price From</h2>
                <span class="px-3 py-1 bg-gray-100 rounded-full opacity-95 mt-2 inline-block text-sm"><svg class="w-4 h-4 inline-block mr-2" width="42" height="42" viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h42v42H0z"/><path d="M34.154 5.5a6.346 6.346 0 016.346 6.346v18.308a6.346 6.346 0 01-6.346 6.346H7.846A6.346 6.346 0 011.5 30.154V11.846A6.346 6.346 0 017.846 5.5zM37.5 17.906h-33v12.248c0 1.786 1.4 3.246 3.163 3.341l.183.005h26.308a3.346 3.346 0 003.346-3.346V17.906zM14.077 23.35a1.5 1.5 0 011.5 1.5v1.75a1.5 1.5 0 01-1.5 1.5H9.923a1.5 1.5 0 01-1.5-1.5v-1.75a1.5 1.5 0 011.5-1.5zM34.154 8.5H7.846A3.346 3.346 0 004.5 11.846v1.06h33v-1.06c0-1.786-1.4-3.246-3.163-3.341l-.183-.005z" fill="currentColor" fill-rule="nonzero"/></g></svg>&pound;{{ (Math.round(content.price * 100) / 100).toFixed(2) }}</span>
            </div>
            <div class="flex flex-wrap justify-between mt-auto button-row items-center absolute bottom-0 py-4 px-10 left-0 right-0 bg-white shadow-t">
                <span v-if="content.gallery.length === 0" class="w-10 h-10"></span>
                <ion-button v-if="content.gallery.length > 0" icon-only fill="clear" class="self-start w-10 h-10 text-gray-400" @click="openModal(id, 'gallery')">
                    <ion-icon slot="icon-only" :icon="image" size="large"></ion-icon>
                </ion-button>
                <ion-button icon-only fill="clear" class="self-center w-10 h-10 text-gray-400" @click="closeModal()">
                    <ion-icon slot="icon-only" :icon="closeCircle" size="large"></ion-icon>
                </ion-button>
                <span class="w-10 h-10"></span>
            </div>
        </div>
        <div class="flex flex-col px-4 py-10 h-full overflow-auto pb-26"  v-if="type === 'gallery'">
            <h1 class="flex justify-center items-center mb-6 text-gray-400"><ion-icon slot="icon-only" :icon="image" size="large" class="mr-2 w-6 h-6"></ion-icon> Product Gallery</h1>
            <div class="flex flex-col gap-2">
                <div
                    v-for="image in content.gallery"
                    :key="image"
                    :id="image.id"
                >
                    <ion-img :src="'https://www.savemoneycutcarbon.com/wp-content/uploads'+image"></ion-img>
                </div>
            </div>
            <div class="flex flex-wrap justify-between mt-auto button-row items-center absolute bottom-0 py-4 px-10 left-0 right-0 bg-white shadow-t">
                <ion-button icon-only fill="clear" class="self-start w-10 h-10 text-gray-400" @click="openModal(id, 'info')">
                    <ion-icon slot="icon-only" :icon="informationCircle" size="large"></ion-icon>
                </ion-button>
                <ion-button icon-only fill="clear" class="self-center w-10 h-10 text-gray-400" @click="closeModal()">
                    <ion-icon slot="icon-only" :icon="closeCircle" size="large"></ion-icon>
                </ion-button>
                <span class="w-10 h-10"></span>
            </div>
        </div>
    </ion-content>
</template>

<script>
import {IonContent, IonHeader, IonTitle, IonIcon, IonImg, IonToolbar, IonButtons, IonButton, modalController} from '@ionic/vue';
import {informationCircle, image, closeCircle} from 'ionicons/icons';
import Modal from '@/components/Modal.vue'
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'Modal',
    props: {
        title: {type: String, default: 'Super Modal'},
        id: {type: Object},
        type: {type: String, default: 'info'}
    },
    setup() {
        const closeModal = () => {
            modalController.dismiss();
        };

        const openModal = async (id, type) => {
            const modal = await modalController.create({
                component: Modal, //Modal is name of the component to render inside ionic modal
                componentProps: {
                    id: id,
                    type: type
                },
                swipeToClose: true,
                backdropDismiss: true,
            });
            return modal.present();
        };

        return {
            closeModal,
            openModal
        };
    },
    data() {
        return {
            informationCircle,
            image,
            closeCircle,
            content: this.$props.id
        }
    },
    components: {IonContent, IonHeader, IonTitle, IonIcon, IonImg, IonToolbar, IonButtons, IonButton},
});

</script>

<style scoped>
ion-button {
    padding: 0;
    --padding-start: 0;
    --padding-end: 0;
}

ion-icon {
    --ionicon-stroke-width: 16px;
}
</style>
