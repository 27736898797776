<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-menu-button></ion-menu-button>
                </ion-buttons>
                <ion-title><ion-img :src="require('@/assets/images/SustainAbility-logo.svg')" class="w-20 mx-auto"></ion-img></ion-title>
                <ion-buttons slot="end">
                    <ion-button @click="openModal(id, 'tutorial')">
                        <ion-img :src="require('@/assets/Icons/help.svg')" class="w-6 h-6"></ion-img>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-router-outlet id="main"></ion-router-outlet>
        <ion-content fullscreen>
            <SwapsList name="My Swap List"/>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonImg,
    IonContent,
    IonRouterOutlet,
    IonButton,
    IonButtons,
    IonMenuButton,
    modalController
} from '@ionic/vue';
import SwapsList from '@/components/SwapsList.vue';
import TutorialModal from '@/components/TutorialModal.vue';

export default {
    name: 'Swaps List',
    components: {
        SwapsList,
        IonPage,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonImg,
        IonContent,
        IonRouterOutlet,
        IonButtons,
        IonButton,
        IonMenuButton
    },

        setup() {

        const openModal = async (id, type) => {
            const modal = await modalController.create({
                component: TutorialModal, //Modal is name of the component to render inside ionic modal
                componentProps: {
                    id: id,
                    type: type
                },
                swipeToClose: true,
                backdropDismiss: true,
            });
            return modal.present();
        };

        return {
            openModal
        };
    },
}
</script>
