<template>
    <div id="container" class="h-full">
        <h1 class="text-center text-gray-400 pt-2">{{ name }}</h1>
        <div class="flex flex-col relative pb-60" v-if="loadedSwap.length !== 0">
            <ion-card
                v-for="swap in loadedSwap"
                :key="swap"
                :id="swap.id"
                class="border-2 border-green-500 rounded-xl mb-0"
            >
                <div class="flex items-center">
                    <div class="w-1/3 relative">
                        <div class="bg-gradient-to-l to-transparent via-transparent from-white absolute w-full h-full"></div>
                        <ion-img :src="'https://www.savemoneycutcarbon.com/wp-content/uploads'+swap.product_image_secondary" class="object-cover w-full h-auto hsm:hidden" alt="main image" ></ion-img>
                        <ion-img :src="'https://www.savemoneycutcarbon.com/wp-content/uploads'+swap.product_image_main" class="hidden object-cover w-auto h-auto hsm:block" alt="main image" ></ion-img>
                    </div>
                    <ion-card-header class="w-2/3 ">
                        <ion-card-title class="text-base text-smcc-smcc-grey font-bold leading-tight text-xs">{{ swap.name.split("|")[0] }}</ion-card-title>
                        <span class="px-3 py-1 bg-gray-100 rounded-full opacity-95 mt-2 inline-block"><svg class="w-4 h-4 inline-block mr-2" width="42" height="42" viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h42v42H0z"/><path d="M34.154 5.5a6.346 6.346 0 016.346 6.346v18.308a6.346 6.346 0 01-6.346 6.346H7.846A6.346 6.346 0 011.5 30.154V11.846A6.346 6.346 0 017.846 5.5zM37.5 17.906h-33v12.248c0 1.786 1.4 3.246 3.163 3.341l.183.005h26.308a3.346 3.346 0 003.346-3.346V17.906zM14.077 23.35a1.5 1.5 0 011.5 1.5v1.75a1.5 1.5 0 01-1.5 1.5H9.923a1.5 1.5 0 01-1.5-1.5v-1.75a1.5 1.5 0 011.5-1.5zM34.154 8.5H7.846A3.346 3.346 0 004.5 11.846v1.06h33v-1.06c0-1.786-1.4-3.246-3.163-3.341l-.183-.005z" fill="currentColor" fill-rule="nonzero"/></g></svg>&pound;{{ (Math.round(swap.price * 100) / 100).toFixed(2) }}</span>
                    </ion-card-header>
                </div>
                <ion-card-content class="bg-gray-50 mt-auto py-1">
                    <div class="flex justify-between items-center w-full pointer-events-auto button-row">
                        <ion-button icon-only fill="clear" class="w-10 h-10 text-xl text-gray-400" @click="openModal(swap, 'info')">
                            <ion-icon slot="icon-only" :icon="informationCircle" size="large"></ion-icon>
                        </ion-button>
                        <ion-button icon-only fill="clear" class="w-10 h-10 text-gray-400" @click="openModal(swap, 'gallery')">
                            <ion-icon slot="icon-only" :icon="image" size="large"></ion-icon>
                        </ion-button>
                        <span></span>
                        <ion-button icon-only fill="clear" class="w-10 h-10 text-gray-400 self-end" @click="presentAlertConfirm(swap)">
                            <ion-icon slot="icon-only" :icon="closeCircle" size="large"></ion-icon>
                        </ion-button>
                    </div>
                </ion-card-content>
            </ion-card>
        </div>
        <div
            v-if="loadedSwap.length === 0"
            class="shadow-none bg-white
                        flex items-center flex-col flex-wrap content-end transition duration-700 transform cursor-pointer sm:max-w-sm h-full"
        >
            <div class="p-4 flex flex-col justify-center h-full">
                <ion-img :src="require('@/assets/Icons/swap-sad.svg')" class="h-10 w-10 mx-auto block mb-3"></ion-img>
                <div class="text-center">
                    <h2 class="pb-7.5 text-green-500 text-lg font-bold">You haven't chosen any swaps yet...</h2>
                    <p class="pb-4 text-md text-smcc-grey-dark">View a room to start swapping. Once selected, your sustainable swaps will appear here.</p>
                </div>
                <ion-button fill="clear" class="border-2 border-smcc-smcc-green text-smcc-smcc-green text-center block mx-4 my-2" :router-link="`/home/rooms/`">
                    <ion-icon :src="require('@/assets/Icons/arrow-back.svg')" class="h-6 w-6 py-6 px-1"></ion-icon> View Rooms
                </ion-button>
            </div>
        </div>
    </div>
    <div v-if="loadedSwap.length !== 0" class="flex flex-wrap justify-between mt-auto button-row bottom-0 fixed py-2.5 px-7.5 left-0 right-0 bg-white shadow-t buy">
        <ion-button fill="clear" class="bg-white border-2 border-green-500 h-15 p-0 m-0 w-full text-green-500 font-bold block rounded" @click="packageSwapsToWishList">
            View &amp; Buy Online <ion-icon :src="require('@/assets/Icons/arrow-forward-green.svg')" class="h-6 w-6 py-6 px-1"></ion-icon>
        </ion-button>
    </div>
</template>

<script>
import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonIcon,
    IonButton,
    IonImg,
    modalController, alertController, loadingController } from '@ionic/vue';
import { informationCircle, image, closeCircle} from 'ionicons/icons';
import { defineComponent, inject } from 'vue';
import Modal from '@/components/Modal.vue'
import {get, set} from "@/store/storage";
import {Http} from '@capacitor-community/http';
import { TokenService } from "@/services/token.service";
import { AuthService } from "@/services/auth.service";
import { Browser } from '@capacitor/browser';
import {mapActions} from "vuex";
import {mapGetters} from "vuex"

export default defineComponent({
    components: {
        IonCard,
        IonCardContent,
        IonCardHeader,
        IonCardTitle,
        IonIcon,
        IonButton,
        IonImg
    },
    name: 'ExploreContainer',
    props: {
        name: String
    },
    setup() {
        const openModal = async (id, type) => {
            const modal = await modalController.create({
                component: Modal, //Modal is name of the component to render inside ionic modal
                componentProps: {
                    id: id,
                    type: type
                },
                swipeToClose: true,
                backdropDismiss: true,
            });
            return modal.present();
        };
        return {
            openModal
        }
    },
    data() {
        const emitter = inject("emitter");   // Inject `emitter`
        return {
            informationCircle,
            image,
            closeCircle,
            loadedSwap: [],
            emitter
        }
    },
    computed: {
        ...mapGetters("auth", [
            "authenticating",
            "authenticationError",
            "authenticationErrorCode"
        ])
    },
    methods:{
        ...mapActions("auth", ["signOut", "refreshToken"]),
        async openCapacitorSite(url, access_token) {
            await Browser.open({ url: url+'&access_token='+access_token, headers: { 'Authorization': 'BEARER ' + access_token, }  }).then(() => {
                loadingController.dismiss().then(r => console.log(r));
            });
        },
        async presentAlertConfirm(swap) {
            const alert = await alertController
                .create({
                    cssClass: 'basic-alert',
                    header: 'Reject swap:',
                    message: '<strong>' + swap.name + '</strong>' + '<br><br>Are you sure?',
                    buttons: [
                        {
                            text: 'Yes',
                            handler: () => {
                                console.log('Confirm Okay')
                                console.log(swap)
                                this.removeSwap(swap);

                            },
                        },
                        {
                            text: 'No',
                            role: 'cancel',
                            handler: (blah) => {
                                console.log('Confirm Cancel:', blah)
                            },
                        },
                    ],
                });
            return alert.present();
        },
        async removeSwap(theswap) {
            const room = theswap.room_id[0].toString();
            const swap = theswap.swap_id[0].toString();
            const product = theswap.id.toString();
            const remove = {room, swap, product};
            const list = await get('accepted_products');

            let index = list.findIndex(
                i => i.room === remove.room &&
                    i.swap === remove.swap &&
                    i.product === remove.product
            );

            // TODO: Add confirmation box

            list.splice(index, 1)
            await set('accepted_products', list);

            this.$store.getters.filterBySwaps().then(response => this.loadedSwap = response);

            // TODO: Add toast for removal
        },
        async packageSwapsToWishList() {
            const loading = await loadingController.create({
                message: 'Creating your wishlist'
            });
            await loading.present();
            await AuthService.getUser();
            let list = await get('accepted_products');
            list = list.map((item)=>{return item.product})

            const user = await get('user_data');
            const access_token = TokenService.getToken();
            const options = {
                url: 'https://www.savemoneycutcarbon.com/my-lists/',
                headers: {
                    'Authorization': 'BEARER ' + access_token,
                    "Content-type": "application/x-www-form-urlencoded"
                },
                data: {
                    sustain_ability_user: user.ID, 
                    sustain_ability_products: JSON.stringify(list),
                    sustain_ability_authorization: btoa('fellowship') + "|" + btoa(Math.floor(Date.now() / 1000)) 
                },
            };

            await Http.post(options).then((r) => {
                this.openCapacitorSite(r.url, access_token)
            });

        }
    },
    mounted() {
        this.emitter.on("undo", async (value) => {   // *Listen* for event
            console.log("undo", `value: ${value}`);
            const action = await get('last_action');
            const object = JSON.stringify(action.value);
            const filter = this[action.set].filter(function(a){ return JSON.stringify(a) !== object });

            await set([action.set], filter);
            this.loadedSwap = await this.$store.getters.filterBySwaps();
        });
    },
    async beforeCreate() {
        /**
         * Filter by swaps...
         * TODO: this function needs to be changed so it returns actual swaps, currently
         * just returning whats left after declines
         */
        await this.$store.getters.filterBySwaps().then(response => this.loadedSwap = response);
    },
    async ionViewWillEnter() {
        await this.$store.getters.filterBySwaps().then(response => this.loadedSwap = response);
    }

});
</script>

<style >
.card-content-ios {
    -webkit-padding-start: 10px;
    padding-inline-start: 10px;
    -webkit-padding-end: 10px;
    padding-inline-end: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
}
ion-button {
    padding: 0;
    --padding-start: 0;
    --padding-end: 0;
}
.basic-alert {
    --backdrop-opacity: 0.6;
    --background: black;
}
.alert-title.sc-ion-alert-md, .alert-title.sc-ion-alert-ios {
    @apply text-white text-left text-sm font-normal
}
.alert-message.sc-ion-alert-md, .alert-message.sc-ion-alert-ios {
    @apply text-white text-left
}
.alert-button-group.sc-ion-alert-ios, .alert-button-group.sc-ion-alert-md {
    @apply p-4 gap-2 justify-items-center
 }
.alert-button.sc-ion-alert-md, .alert-button.sc-ion-alert-ios {
    border:inherit;
    min-width: auto;
    @apply text-white bg-black border border-b-2 border-t-2 border-l-2 border-r-2 border-white rounded-sm;
}
.alert-button.sc-ion-alert-md:last-child, .alert-button.sc-ion-alert-ios:last-child {
    border-right: inherit;
    @apply border-r-2 border-white font-normal
}
.ios .buy {
    @apply bottom-20
}
</style>
